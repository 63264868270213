/* .project-add-wrapper {
  height: 500px !important;
} */


.project-add-wrapper .ant-form-item {
  margin-bottom: 0px;
}

.project-add-wrapper .ant-collapse {
  border-radius: 0px;
  border-right: none;
  border-left: none;
}

.project-add-wrapper .ant-modal-content {
  overflow: hidden;
  height: 100%;
}

.project-add-wrapper>div {
  height: 100%;
}

.project-add-wrapper .list-project .ant-collapse-content-box {
  padding: 12px 0 !important;
}

.project-add-wrapper .list-project {
  padding-left: 5px;
}

.project-add-wrapper .ant-collapse>.ant-collapse-item:last-child,
.project-add-wrapper .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: unset !important;
}