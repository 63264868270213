.unauthorised-container {
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 30px;
    border-radius: 10px;
}

.unauthorised-container h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.unauthorised-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.unauthorised-container .back-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    display: inline-block;
}

.unauthorised-container .back-button:hover {
    background-color: #0056b3;
}