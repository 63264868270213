.account-login .form-center .login-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-42%);
    color: #187cb7;
    z-index: 8;
    height: 100%;
    transition: none;
}

.account-login .form-center .ant-input {
    background-color: #fff;
}