/* .ant-spin-container .ant-table-content {
    height: calc(100vh - 440px);
    overflow: auto;
} */

/* .new-block-table .ant-table-content {
    height: calc(100vh - 580px);
    overflow: auto;
} */

.ant-layout>.gx-layout-content {
    height: calc(100vh - 63px);
    overflow: hidden;
}

.ant-card-body {
    max-height: 100%;
    overflow: auto;
}

.ant-card-bordered {
    height: 100%;
}

.project-wrapper .peoject-page {
    overflow: unset;
}

.profileleftbar>ul {
    max-height: 590px;
    overflow-y: auto;
    height: 100%;
    overflow-x: hidden;
}

.profilerightbar .notes.container {
    max-height: calc(100% - 100px);
    overflow-y: auto;
}

.ant-form-item-control-input .ant-form-item-control-input-content .ant-select-multiple .ant-select-selector {
    max-height: 300px;
    overflow-y: auto;
    padding: 4px;
    overflow-x: hidden;
    min-height: 50px;
    font-size: 14px;
}

.ant-modal-body .overview-modal-wrapper .topic-cancel-wrapper .list-modal-stages {
    max-height: 300px;
    overflow-y: auto;
}

.overview-modal-wrapper .ant-form-item-control-input .ant-select-selector .ant-select-selection-overflow {
    max-height: 149px;
}

/* ---------------- all projects------------ */

.about-project {
    height: 100%;
    max-height: calc(100% - 60px);
}

.ant-card-body {
    height: 100%;
}

.about-project .ant-tabs.ant-tabs-top.ant-tabs-mobile.ant-tabs-centered {
    height: 100%;
}

.about-project .ant-tabs-content.ant-tabs-content-top {
    height: 100%;
}

/* ------------------------------------ */

.logtime-pop-wrapper .log-time-data::-webkit-scrollbar-track,
.common-drawer-projectlist-wrapper .ant-drawer-body::-webkit-scrollbar-track,
.task-model .left-task-detail-panel .task-inner-card::-webkit-scrollbar-track,
.right-popover-wrapper .assigness-data::-webkit-scrollbar-track,
.list-project::-webkit-scrollbar-track,
.assignees-popover .assigness-data::-webkit-scrollbar-track,
.bug-task-panel .comment-textarea .fileAttachment_container::-webkit-scrollbar-track,
.right-task-detail-panel .comment-list-wrapper::-webkit-scrollbar-track,
.notes-details-model .comment-textarea .fileAttachment_container::-webkit-scrollbar-track,
.task-model .comment-textarea .fileAttachment_container::-webkit-scrollbar-track,
.task-model .attachment-comment .table-schedule-wrapper ul::-webkit-scrollbar-track,
.right-task-detail-panel .task-history-inner.task-detail-inner.task::-webkit-scrollbar-track,
.profilerightbar .upload-file-left::-webkit-scrollbar-track,
.file-wrapper .profileleftbar>ul::-webkit-scrollbar-track,
.bugs-task-wrapper .drag_container .project-task-list .kanbanView-bugs-data::-webkit-scrollbar-track,
.discussion-module .discussion-comment-block .comment-module::-webkit-scrollbar-track,
.discussion-module .profileleftbar .project-update::-webkit-scrollbar-track,
.drag_column .borad-task-data::-webkit-scrollbar-track,
.task-wrapper .profileleftbar>ul::-webkit-scrollbar-track {
    background-color: #f1f1f175;
}

.logtime-pop-wrapper .log-time-data:hover:-webkit-scrollbar-track,
.common-drawer-projectlist-wrapper .ant-drawer-body:hover:-webkit-scrollbar-track,
.task-model .left-task-detail-panel .task-inner-card:hover:-webkit-scrollbar-track,
.right-popover-wrapper .assigness-data:hover:-webkit-scrollbar-track,
.list-project:hover:-webkit-scrollbar-track,
.assignees-popover .assigness-data:hover:-webkit-scrollbar-track,
.bug-task-panel .comment-textarea .fileAttachment_container:hover:-webkit-scrollbar-track,
.right-task-detail-panel .comment-list-wrapper:hover:-webkit-scrollbar-track,
.notes-details-model .comment-textarea .fileAttachment_container:hover:-webkit-scrollbar-track,
.task-model .comment-textarea .fileAttachment_container:hover:-webkit-scrollbar-track,
.task-model .attachment-comment .table-schedule-wrapper ul:hover:-webkit-scrollbar-track,
.right-task-detail-panel .task-history-inner.task-detail-inner.task:hover:-webkit-scrollbar-track,
.profilerightbar .upload-file-left:hover:-webkit-scrollbar-track,
.file-wrapper .profileleftbar>ul:hover:-webkit-scrollbar-track,
.bugs-task-wrapper .drag_container .project-task-list .kanbanView-bugs-data:hover:-webkit-scrollbar-track,
.discussion-module .discussion-comment-block .comment-module:hover:-webkit-scrollbar-track,
.discussion-module .profileleftbar .project-update:hover:-webkit-scrollbar-track,
.drag_column .borad-task-data:hover:-webkit-scrollbar-track,
.task-wrapper .profileleftbar>ul:hover::-webkit-scrollbar-track {
    background-color: #f1f1f1a6;
}

.logtime-pop-wrapper .log-time-data::-webkit-scrollbar-thumb,
.common-drawer-projectlist-wrapper .ant-drawer-body::-webkit-scrollbar-thumb,
.task-model .left-task-detail-panel .task-inner-card::-webkit-scrollbar-thumb,
.right-popover-wrapper .assigness-data::-webkit-scrollbar-thumb,
.list-project::-webkit-scrollbar-thumb,
.assignees-popover .assigness-data::-webkit-scrollbar-thumb,
.bug-task-panel .comment-textarea .fileAttachment_container::-webkit-scrollbar-thumb,
.right-task-detail-panel .comment-list-wrapper::-webkit-scrollbar-thumb,
.notes-details-model .comment-textarea .fileAttachment_container::-webkit-scrollbar-thumb,
.task-model .comment-textarea .fileAttachment_container::-webkit-scrollbar-thumb,
.task-model .attachment-comment .table-schedule-wrapper ul::-webkit-scrollbar-thumb,
.right-task-detail-panel .task-history-inner.task-detail-inner.task::-webkit-scrollbar-thumb,
.profilerightbar .upload-file-left::-webkit-scrollbar-thumb,
.file-wrapper .profileleftbar>ul::-webkit-scrollbar-thumb,
.bugs-task-wrapper .drag_container .project-task-list .kanbanView-bugs-data::-webkit-scrollbar-thumb,
.discussion-module .discussion-comment-block .comment-module::-webkit-scrollbar-thumb,
.discussion-module .profileleftbar .project-update::-webkit-scrollbar-thumb,
.drag_column .borad-task-data::-webkit-scrollbar-thumb,
.task-wrapper .profileleftbar>ul::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
}

.logtime-pop-wrapper .log-time-data::-webkit-scrollbar-thumb,
.common-drawer-projectlist-wrapper .ant-drawer-body::-webkit-scrollbar-thumb,
.task-model .left-task-detail-panel .task-inner-card::-webkit-scrollbar-thumb,
.right-popover-wrapper .assigness-data::-webkit-scrollbar-thumb,
.assignees-popover .assigness-data::-webkit-scrollbar-thumb,
.bug-task-panel .comment-textarea .fileAttachment_container::-webkit-scrollbar-thumb,
.right-task-detail-panel .comment-list-wrapper::-webkit-scrollbar-thumb,
.notes-details-model .comment-textarea .fileAttachment_container::-webkit-scrollbar-thumb,
.task-model .comment-textarea .fileAttachment_container::-webkit-scrollbar-thumb,
.task-model .attachment-comment .table-schedule-wrapper ul::-webkit-scrollbar-thumb,
.right-task-detail-panel .task-history-inner.task-detail-inner.task::-webkit-scrollbar-thumb,
.profilerightbar .upload-file-left:hover::-webkit-scrollbar-thumb,
.file-wrapper .profileleftbar>ul:hover::-webkit-scrollbar-thumb,
.bugs-task-wrapper .drag_container .project-task-list .kanbanView-bugs-data:hover::-webkit-scrollbar-thumb,
.discussion-module .discussion-comment-block .comment-module:hover::-webkit-scrollbar-thumb,
.discussion-module .profileleftbar .project-update:hover::-webkit-scrollbar-thumb,
.drag_column .borad-task-data:hover::-webkit-scrollbar-thumb,
.task-wrapper .profileleftbar>ul:hover::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 6px;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.task-wrapper .profilerightbar .project-task-section::-webkit-scrollbar {
    height: 15px;
    overflow-x: auto;
}